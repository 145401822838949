function inputFileName () {

  function getName (fileInput) {
    var fileobj = fileInput.files
    var fileSize = 0
    var maxSize = 200000 // 200 Kb

    for (var i = 0; i < fileobj.length; i++) {
      document.querySelectorAll('.label-attach-field')[0].innerHTML = fileobj[i].name
      fileSize = fileobj[i].size
    }

    if (fileSize > maxSize) {
      document.querySelector('#filesize').classList.add('-visible')
      document.querySelectorAll('.cumplo-form_button')[0].setAttribute('disabled', 'disabled')
    } else {
      document.querySelector('#filesize').classList.remove('-visible')
      document.querySelectorAll('.cumplo-form_button')[0].removeAttribute('disabled')
    }
  }

  document.getElementById('attach-field').onchange = function () {
    getName(this)
  }
}

export default inputFileName