import menuMobile from './menuMobile'
import googleMap from './googleMap'
import inputFileName from './inputFileName'
import parallaxTitle from './parallaxTitle'
import sendFormData from './sendFormData'

document.addEventListener('DOMContentLoaded', () => {
  menuMobile()
  googleMap()
  inputFileName()
  parallaxTitle()
  sendFormData()
});
