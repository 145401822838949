function parallaxTitle () {

  const title = document.querySelector('.js-parallax')
  const speed = 0.2
  title.style.transform = 'translateY( calc( var(--scrollparallax) * 2px ) )'
     
  function setScrollParallax() {
      title.style.setProperty("--scrollparallax", (document.body.scrollTop || document.documentElement.scrollTop) * speed)
      window.requestAnimationFrame( setScrollParallax )
  }

  window.requestAnimationFrame( setScrollParallax )
}

export default parallaxTitle