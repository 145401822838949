function sendFormData () {

  function checkRecaptcha () {
    const recaptchaElem = document.querySelector('#g-recaptcha-response')
    let recaptchaVal = recaptchaElem.value
    if (recaptchaVal !== '') {
      return true
    }
  }

  function sendData () {
    document.querySelectorAll('.cumplo-form_button')[0].setAttribute('disabled', 'disabled')
    var XHR = new XMLHttpRequest()
    var FD = new FormData(form)
    XHR.addEventListener('load', function(event) {
      form.classList.add('inactive')
      var success = document.querySelectorAll('.post-send')[0]
      success.classList.add('active')
    });
    XHR.addEventListener('error', function(event) {
      form.classList.add('inactive')
      var error = document.querySelectorAll('.post-send-error')[0]
      error.classList.add('active')
      document.querySelectorAll('.cumplo-form_button')[0].removeAttribute('disabled')
    });
    XHR.open('POST', '#')
    XHR.send(FD)
  }

  var form = document.querySelectorAll('.cumplo-form')[0]
  form.addEventListener('submit', function (e) {
    e.preventDefault()
    if (checkRecaptcha()) {
      document.querySelector('#captcha').classList.remove('-visible')
      sendData()
    } else {
      document.querySelector('#captcha').classList.add('-visible')
      return false
    }
  })

}

export default sendFormData