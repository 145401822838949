function googleMap () {
  
	var map
  var myLatLng = {lat: -33.4096799, lng: -70.5727986}
  map = new google.maps.Map(document.getElementById('map'), {
    zoom: 15,
    center: myLatLng
  })

  var marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    title: 'Cumplo'
  })

}

export default googleMap